export const MEMBERSHIPTYPE = {
    "ARTISAN/CRAFTSMAN": "ARTISAN/CRAFTSMAN",
    "STUDENT": "STUDENT",
    "TECHNICIAN": "TECHNICIAN",
    "LICENCIATE": "LICENCIATE",
    "TECHNOLOGIST": "TECHNOLOGIST",
    "GRADUATE": "GRADUATE",
    "CORPORATE": "CORPORATE",
    "FELLOW": "FELLOW",
    "NON-MEMBER": "NON-MEMBER"
}
