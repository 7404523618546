import { store } from '../../app/store'
import { registrantsApiSlice } from '../registrants/registrantsApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(registrantsApiSlice.util.prefetch('getRegistrants', 'registrantsList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch
