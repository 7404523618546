import { Routes, Route } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from "@mui/material"
import { ColorModeContext, useMode } from "./theme"
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Public'
import DashLayout from './components/DashLayout'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import Registrants from './features/registrants/RegistrantsList'
import NewRegistrantForm from './features/registrants/NewRegistrantForm'
import QueryRegistrant from './features/registrants/QueryRegistrant'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle'

function App() {
  useTitle('NIOB AGM REGISTRATION')
  const [theme, colorMode] = useMode()

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index element={<Login />} />

            <Route path="registrants">
              <Route index element={<Registrants />} />
              <Route path="new" element={<NewRegistrantForm />} />
              <Route path="check" element={<QueryRegistrant />} />
              <Route path=":id" />
            </Route>

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                <Route element={<Prefetch />}>
                  <Route path="dash" element={<Registrants />}>


                    {/*<Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
                      <Route path="users">
                        <Route index element={<UsersList />} />
                        <Route path=":id" element={<EditUser />} />
                        <Route path="new" element={<NewUserForm />} />
                      </Route>
                    </Route>*/}
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
