import { Outlet, Navigate, useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"
import PulseLoader from 'react-spinners/PulseLoader'

const PersistLogin = () => {
    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    const [trueSuccess, setTrueSuccess] = useState(false)
    const navigate = useNavigate()

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()

    useEffect(() => {
        if (effectRan.current === true || process.env.NODE_ENV !== 'development') {
            const verifyRefreshToken = async () => {
                try {
                    await refresh()
                    setTrueSuccess(true)
                } catch (err) {
                    console.error(err)
                }
            }

            if (!token && persist) verifyRefreshToken()
        }

        return () => effectRan.current = true
    }, [token, persist, refresh])

    useEffect(() => {
        if (isSuccess && trueSuccess) {
            navigate('/dash', { replace: true })  // Use replace to avoid adding to history stack
        }
    }, [isSuccess, trueSuccess, navigate])

    let content
    if (!persist) {
        content = <Outlet />
    } else if (isLoading) {
        content = <PulseLoader color={"#03a455"} className="center" />
    } else if (isError) {
        content = <Navigate to="/" replace />
    } else if (isSuccess && trueSuccess) {
        content = <Outlet />
    } else if (token && isUninitialized) {
        content = <Outlet />
    } else {
        content = <Navigate to="/" replace />
    }

    return content
}

export default PersistLogin
