import { useState, useEffect } from "react"
import { useAddNewRegistrantMutation } from "./registrantsApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { STATES } from '../../config/states'
import { MEMBERSHIPTYPE } from '../../config/membershiptype'
import { useSelector } from 'react-redux'
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl,
  InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions, CardContent,
  Alert, Collapse} from "@mui/material"
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Papa from 'papaparse'

const USER_REGEX = /^[A-z. ]{3,50}$/
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/
const PHONE_REGEX = /^[0-9]{11}$/

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const NewRegistrantForm = () => {
    useTitle('NIOB AGM REGISTRATION: New Registrant')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()

    const [addNewRegistrant, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewRegistrantMutation()

    const [fullName, setFullName] = useState('')
    const [validFullName, setValidFullName] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [chapter, setChapter] = useState('')
    const [membershipType, setMembershipType] = useState('')
    const [alert, setAlert] = useState(null)

    useEffect(() => {
        setValidFullName(USER_REGEX.test(fullName))
    }, [fullName])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone))
    }, [phone])

    useEffect(() => {
        if (isSuccess) {
            setAlert("Your details have been captured successfully. Proceed to make payment at the ACCOUNT stand!")
            setTimeout(() => navigate("/registrants/check", { replace: true }), 20000)
        }
    }, [isSuccess, navigate])

    const onFullNameChanged = e => setFullName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPhoneChanged = e => setPhone(e.target.value)

    const onSaveRegistrantClicked = async (e) => {
        e.preventDefault()

        await addNewRegistrant({ fullName: fullName.toUpperCase(), email, phone, chapter: chapter.toUpperCase(), membershipType: membershipType.toUpperCase() })

    }

    const [file, setFile] = useState(null)
    const [data, setData] = useState()

    const handleBulkUploadFileChange = (event) => {
      const selectedFile = event.target.files[0]
      setFile(selectedFile)
    }

    const handleBulkUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) {
        console.log("No file selected")
      } else {
        Papa.parse(file, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: async function (result) {
            try {
              result.data.map( async (row) => {
                const response = await addNewRegistrant({
                    "fullName":row.fullName,
                    "email":row.email,
                    "phone":row.phone,
                    "chapter":row.chapter.toUpperCase(),
                    "membershipType":row.membershipType,
                    "token":row.token,
                    "paid":row.paid,
                    "datePaid":row.datePaid
                })
              })

            } catch (err) {
              // Handle error response from the server
              console.error('File upload failed:', err.message)
              if (!err?.response) {
                setErrMsg2('No Server Response')
              } else if (err.response?.status === 400) {
                setErrMsg2(err.response?.data.data)
              } else if (err.response?.status === 401) {
                setErrMsg2(err.response?.data.data)
              } else {
                setErrMsg2(err.response?.data.data)
              }
              errRef2.current.focus()
            }
          }
        })
      }
    }


    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(MEMBERSHIPTYPE).map(_membershiptype => (
        <MenuItem key={_membershiptype} value={_membershiptype}>{_membershiptype}</MenuItem>
    ))

    const canSave = [membershipType, validFullName, validEmail].every(Boolean) && !isLoading

    return (
      <>
      {/*<Header title="NEW REGISTRANT" subtitle="Register a New Registrant" />*/}
      <Box sx={{
          width: "100%",
          p: isMobile ? "10% 5%" : "2% 20%",
          "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010', color: '#fff'}
        }}>
            <img src="../../logo.png" style={{width:"100%", marginBottom:isMobile ? "30px" : "10px"}}/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "95%" }}>

                <Stack direction="row" gap={1} mt="20px" mb="20px">
                  <Link to="/">
                    <Button variant="outlined" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/registrants/check">
                    <Button variant="outlined" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                      Check Register
                    </Button>
                  </Link>
                </Stack>
            </Box>
            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                <Box sx={{
                  width: isMobile ? "95%" : '100%'
                }}>
                    <form onSubmit={onSaveRegistrantClicked}>
                        <Box
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                          }}
                        >
                            <Collapse in={alert} sx={{ width: "100%", gridColumn: "span 4" }}>
                              <Alert
                                variant="contained"
                                sx={{ color: "#fff", backgroundColor: "#0b1c08" }}
                                severity="success"
                                aria-live="assertive"
                                onClose={() => {setAlert(null)}}
                              >
                                  {alert}
                              </Alert>
                            </Collapse>

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Full Name"
                              onChange={onFullNameChanged}
                              value={fullName}
                              name="fullName"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="email"
                              label="Email"
                              onChange={onEmailChanged}
                              value={email}
                              name="email"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Phone Number"
                              onChange={onPhoneChanged}
                              value={phone}
                              name="phone"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <FormControl sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="chapter-id">State Chapter</InputLabel>
                              <Select
                                fullWidth
                                labelId="chapter-id"
                                variant="outlined"
                                value={chapter}
                                name="chapter"
                                onChange={(e) => setChapter(e.target.value)}
                              >
                                {options1}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="membershipType-id">MembershipType</InputLabel>
                              <Select
                                labelId="membershipType-id"
                                variant="outlined"
                                name="membershipType"
                                value={membershipType}
                                onChange={(e) => setMembershipType(e.target.value)}
                              >
                                {options2}
                              </Select>
                            </FormControl>
                        </Box>
                        <Box display="flex" mt="30px">
                          <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"50%"}} variant="contained">
                            Add New Registrant
                          </LoadingButton>
                        </Box>
                    </form>
                </Box>
                {/*<Grid gap={4} sx={{ width: isMobile ? "95%" : "40%" }}>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ bgcolor: colors.primary[400] }} >
                      <CardHeader
                        title={"BULK REGISTER REGISTRANTS"}
                        subheader={"UPLOAD .csv FILE"}
                      />
                      <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Register 2 or more Centres at once by filling and uploading <a
                              href="/bulk_centres.csv" // Adjusted path
                              style={{ color: "#49a139", textDecoration: "underline" }}
                              target="_blank"
                              download="bulk_centres.csv" // Added download attribute
                            >
                              <u>this .csv file</u>
                            </a>.
                          </Typography>
                      </CardContent>
                      <CardActions>
                          <Button component="label" variant="contained" startIcon={""}>
                              Upload file
                              <VisuallyHiddenInput
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={(event) => {
                                  handleBulkUploadFileChange(event)
                                  handleBulkUpload(event)
                                }}
                              />
                          </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>*/}
            </Stack>
        </Box>
        </>
    )
}
export default NewRegistrantForm
