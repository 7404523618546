import * as React from 'react'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl,
    InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions,
    CardContent, Rating, Badge, List, ListItem, ListItemText, ListSubheader,
    ListItemAvatar, Divider} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { tokens } from "../theme"
import useMediaQuery from "@mui/material/useMediaQuery"

const BasicInfoBox = ({ fullName, email, phone, token, membershipType, chapter }) => {
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const content = (
      <Box sx={{
          width: "100%",
          "& .MuiListItemText-root": {
              minWidth: '250px'
          },
          "& .MuiDivider-root": {
              borderColor: "rgba(0, 0, 0, 0.12)"
          }
      }}>
          <Stack direction="column" gap={5}>
              <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)" }} >
                  <List sx={{ width: '100%' }}>
                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                FULL NAME:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}
                              >
                                {fullName}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              FULL NAME:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h5" : "h4" }
                              align="left"
                              sx={{color: "#0b1c08"}}
                            >
                              {fullName}
                            </Typography>
                          </React.Fragment>
                        }
                      /></> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                TOKEN:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}
                              >
                                {token ? token : "YET TO PAY"}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              TOKEN:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              sx={{color: "#0b1c08"}}
                            >
                              {token ? token : "YET TO PAY"}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                EMAIL:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}
                              >
                                {email}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              EMAIL:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              sx={{color: "#0b1c08"}}
                            >
                              {email}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                PHONE NUMBER:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}
                              >
                                {phone ? phone : "N/A"}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              PHONE NUMBER:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              sx={{color: "#0b1c08"}}
                            >
                              {phone ? phone : "N/A"}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                CHAPTER:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}

                              >
                                {chapter}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              CHAPTER:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              sx={{color: "#0b1c08"}}

                            >
                              {chapter}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ mb:1, mt:1 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                MEMBERSHIP GRADE:
                              </Typography>
                              <Typography
                                variant="h4"
                                sx={{color: "#0b1c08"}}
                              >
                                {membershipType}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <>
                      <ListItemText
                        sx={{ maxWidth: "250px" }}
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              color={colors.greenAccent[400]}
                            >
                              MEMBERSHIP GRADE:
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              variant={ isMobile ? "h6" : "h4" }
                              sx={{color: "#0b1c08"}}
                            >
                              {membershipType}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </> }
                    </ListItem>
                  </List>
              </Grid>
          </Stack>
      </Box>
    )

    return content
}

const BasicInfoBoxDash = ({ uln, email, phone, centers, qualifications }) => {
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const content = (
      <Box
        sx={{
          width: isMobile ? "100%" : "100%",
          "& .MuiStack-root .MuiListSubheader-root": {
              backgroundColor: colors.primary[400],
              color: colors.greenAccent[400],
              pl:2
          },
          "& .MuiListItemText-root": {
              minWidth: '80px'
          }
        }}>
          <Stack direction="column" gap={5}>
              <Grid sx={{ bgcolor: colors.primary[400] }} >
                  <List
                    sx={{ width: '100%' }}
                  >
                    <ListItem>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                ULN:
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{color:"#fff"}}
                              >
                                {uln}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                ULN:
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "13px", color:"#fff" }}
                              >
                                {uln}
                              </Typography>
                            </React.Fragment>
                          }
                      /> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem sx={{ pr: 30 }}>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                EMAIL:
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{color:"#fff"}}
                              >
                                {email}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                EMAIL:
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "13px", color:"#fff" }}
                              >
                                {email}
                              </Typography>
                            </React.Fragment>
                          }
                      /> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                PHONE NUMBER:
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{color:"#fff"}}
                              >
                                {phone ? phone : "N/A"}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                PHONE NUMBER:
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "13px", color:"#fff" }}
                              >
                                {phone ? phone : "N/A"}
                              </Typography>
                            </React.Fragment>
                          }
                      /> }
                    </ListItem>

                    <Divider variant="middle" component="li" />

                    <ListItem>
                      { isMobile ?
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                CENTER(s):
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{color:"#fff"}}
                              >
                                {centers}
                              </Typography>
                            </React.Fragment>
                          }
                      />  :
                      <ListItemText
                          sx={{ maxWidth: "300px" }}
                          primary={
                            <React.Fragment>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "10px" }}
                                color={colors.greenAccent[400]}
                              >
                                CENTER(s):
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ fontSize: "13px", color:"#fff" }}
                              >
                                {centers}
                              </Typography>
                            </React.Fragment>
                          }
                      /> }
                    </ListItem>
                  </List>
              </Grid>
          </Stack>
      </Box>
    )

    return content
}

export {
    BasicInfoBox,
    BasicInfoBoxDash
}
