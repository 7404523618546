import { useState, useEffect } from "react"
import { useGetRegistrantsQuery } from "./registrantsApiSlice"
import { Box, TextField, Stack, Alert, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import useTitle from '../../hooks/useTitle'
import { useTheme } from "@mui/material/styles"
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { BasicInfoBox } from "../../components/BasicInfoBox"

const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/

const QueryRegistrant = () => {
    useTitle('NIOB AGM REGISTRATION: Query Registrant')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [searchTerm, setSearchTerm] = useState('')
    const [validSearchTerm, setValidSearchTerm] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [foundRegistrant, setFoundRegistrant] = useState(null)

    useEffect(() => {
        setValidSearchTerm(EMAIL_REGEX.test(searchTerm))
    }, [searchTerm])

    // Use the hook at the top level
    const { data: registrants, isLoading } = useGetRegistrantsQuery("registrantsList")

    const onSearchTermChanged = (e) => setSearchTerm(e.target.value)

    const onSearchRegistrantClicked = (e) => {
        e.preventDefault()
        setErrorMessage('') // Clear previous errors
        setFoundRegistrant(null) // Reset the found registrant

        if (registrants) {
            const registrant = Object.values(registrants.entities).find(r => r.email.toLowerCase() === searchTerm.toLowerCase())

            if (registrant) {
                setFoundRegistrant(registrant)
            } else {
                setErrorMessage('No record found for the provided email address.')
            }
        } else if (!isLoading) {
            setErrorMessage('Failed to load registrants data.')
        }
    }

    const canSave = [validSearchTerm].every(Boolean)

    return (
        <Box sx={{
            width: "100%",
            p: isMobile ? "5% 5%" : "2% 25% 30px 25%",
            "& .MuiStack-root .MuiButton-outlined:hover": { backgroundColor: '#7fd47010', color: '#fff' }
        }}>
            <Stack direction="column" gap={5}>
                <Box sx={{
                    width: isMobile ? "95%" : '100%'
                }}>
                    <img src="../../logo.png" style={{width:"100%", marginBottom:"30px"}}/>
                    <form onSubmit={onSearchRegistrantClicked}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Enter Your Email Address"
                                onChange={onSearchTermChanged}
                                value={searchTerm}
                                name="searchTerm"
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" mt="30px">
                            <LoadingButton type="submit" disabled={!canSave} sx={{ color: "#fff", width: "50%" }} variant="contained">
                                Search Registrant
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
                <Box sx={{
                    width: isMobile ? "95%" : '100%'
                }}>
                    {foundRegistrant ? (
                        <BasicInfoBox
                            fullName={foundRegistrant.fullName}
                            email={foundRegistrant.email}
                            phone={foundRegistrant.phone}
                            token={foundRegistrant.token}
                            membershipType={foundRegistrant.membershipType}
                            chapter={foundRegistrant.chapter}
                        />
                    ) : errorMessage ? (
                        <Alert severity="error">{errorMessage}</Alert>
                    ) : (
                        <Typography variant="body1">Enter an email to search for your details.</Typography>
                    )}
                </Box>
            </Stack>
        </Box>
    )
}

export default QueryRegistrant
