import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isSuperAdmin = false
    let isAccountant = false
    let isTagMaster = false
    let isSchwagGiver = false
    let isManager = false
    let status = "Registrant"

    if (token) {
        const decoded = jwtDecode(token)
        const { email, firstName, lastName, role, id } = decoded.UserInfo

        isSuperAdmin = role === 'SUPER ADMIN'
        isAccountant = role === 'ACCOUNTANT'
        isTagMaster = role === 'TAG MASTER'
        isSchwagGiver = role === 'SCHWAG GIVER'
        isManager = role === 'MANAGER'

        if (isSuperAdmin) status = "SUPER ADMIN"
        if (isAccountant) status = "ACCOUNTANT"
        if (isTagMaster) status = "TAG MASTER"
        if (isSchwagGiver) status = "SCHWAG GIVER"
        if (isManager) status = "MANAGER"

        return { id, email, firstName, lastName, role, status, isSuperAdmin, isAccountant, isTagMaster, isSchwagGiver, isManager }
    }

    return { id: '', email: '', firstName: '', lastName: '', role: '', isSuperAdmin, isAccountant, isTagMaster, isSchwagGiver, isManager, status }
}
export default useAuth
