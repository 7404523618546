import * as React from 'react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetRegistrantsQuery } from './registrantsApiSlice'
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import DashHeader from '../../components/DashHeader2'
import axios from 'axios'
import { STATES } from '../../config/states'

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Box, Button, Stack, Typography, Paper, Chip, Grid, Card, CardHeader, CardActions, CardContent, Rating, Tabs, Tab, Divider, IconButton,
  Avatar, List, ListItem, ListItemText, ListSubheader, ListItemAvatar, Badge, CardMedia, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import PeopleIcon from '@mui/icons-material/People'
import ComputerIcon from '@mui/icons-material/Computer'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import WidgetsIcon from '@mui/icons-material/Widgets'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import SchoolIcon from '@mui/icons-material/School'
import EngineeringIcon from '@mui/icons-material/Engineering'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import RedeemIcon from '@mui/icons-material/Redeem'
import { faPenToSquare, faSearch } from "@fortawesome/free-solid-svg-icons"
import { tokens } from "../../theme"

const RegistrantsList = () => {
    useTitle('NIOB AGM REGISTRATION: All Registrants')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { firstName, lastName, isSuperAdmin, isAccountant, isTagMaster, isSchwagGiver, isManager } = useAuth()
    const navigate = useNavigate()

    const {
        data: registrants,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetRegistrantsQuery('registrantsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handlePaid = (event, id) => navigate(`/dash/registrants/paid/${id}`)
    const handleTagged = (event, id) => navigate(`/dash/registrants/tagged/${id}`)
    const handleCollected = (event, id) => navigate(`/dash/registrants/collected/${id}`)
    const handleView = (event, id) => navigate(`/dash/registrants/registrant/${id}`)

    const rows = useMemo(() => {
        if (!registrants || !registrants.entities) return []

        //const allRegistrants = Object.values(registrants.entities)
        return Object.values(registrants.entities)
    }, [registrants, isSuperAdmin, isAccountant, isTagMaster, isSchwagGiver, isManager])

    // Initialize counts
    let paidCount = 0
    let collectedCount = 0
    let totalCount = 0
    let onSiteCount = 0
    let corporates = 0
    let students = 0
    let fellows = 0
    let nonmembers = 0

    let abiaCount = 0, abujaCount = 0, adamawaCount = 0, akwaibomCount = 0, anambraCount = 0,
      bauchiCount = 0, bayelsaCount = 0, benueCount = 0, bornoCount = 0, crossriverCount = 0,
      deltaCount = 0, ebonyiCount = 0, edoCount = 0, ekitiCount = 0, enuguCount = 0, gombeCount = 0,
      imoCount = 0, jigawaCount = 0, kadunaCount = 0, kanoCount = 0, katsinaCount = 0, kebbiCount = 0,
      kogiCount = 0, kwaraCount = 0, lagosCount = 0, nasarawaCount = 0, nigerCount = 0, ogunCount = 0,
      ondoCount = 0, osunCount = 0, oyoCount = 0, plateauCount = 0, riversCount = 0, sokotoCount = 0,
      tarabaCount = 0, yobeCount = 0, zamfaraCount = 0

    if (registrants) {
        // Get all registrants
        const allRegistrants = Object.values(registrants.entities)

        totalCount = allRegistrants.length // Total number of registrants

        // Calculate counts based on conditions
        paidCount = allRegistrants.filter(registrant => registrant.paid === true).length
        collectedCount = allRegistrants.filter(registrant => registrant.collected === true).length
        const targetDate = new Date('2024-08-20')
        const targetDateString = targetDate.toLocaleDateString('en-US')

        onSiteCount = allRegistrants.filter(registrant => {
            const registrantDate = new Date(registrant.datePaid).toLocaleDateString('en-US')
            return registrantDate >= targetDateString
        }).length

        const corporateType = ["CORPORATE", "GRADUATE", "TECHNOLOGIST", "TECHNICIAN", "LICENTIATE", "LICENCIATE"]
        const studentType = ["STUDENT", "ARTISAN/CRAFTSMAN"]

        corporates = allRegistrants.filter(registrant => corporateType.includes(registrant.membershipType.toUpperCase())).length
        students = allRegistrants.filter(registrant => ((studentType.includes(registrant.membershipType.toUpperCase())) || (registrant.membershipType.toUpperCase() === "NON-MEMBER" && registrant.amount <= 20000))).length
        fellows = allRegistrants.filter(registrant => registrant.membershipType.toUpperCase() === "FELLOW").length
        nonmembers = allRegistrants.filter(registrant => (registrant.membershipType.toUpperCase() === "NON-MEMBER" && registrant.amount > 20000)).length

        corporates = ((corporates / totalCount) * 100).toFixed(1)
        students = ((students / totalCount) * 100).toFixed(1)
        fellows = ((fellows / totalCount) * 100).toFixed(1)
        nonmembers = ((nonmembers / totalCount) * 100).toFixed(1)

        //Calculate Count Per State Chapter
        const registrantsArray = Object.values(registrants.entities)

        registrantsArray.forEach(registrant => {
          const stateChapter = registrant.chapter.toUpperCase()

          switch (stateChapter) {
            case STATES.ABIA:
              abiaCount++
              break
            case STATES.ABUJA:
              abujaCount++
              break
            case STATES.ADAMAWA:
              adamawaCount++
              break
            case STATES.AKWAIBOM:
              akwaibomCount++
              break
            case STATES.ANAMBRA:
              anambraCount++
              break
            case STATES.BAUCHI:
              bauchiCount++
              break
            case STATES.BAYELSA:
              bayelsaCount++
              break
            case STATES.BENUE:
              benueCount++
              break
            case STATES.BORNO:
              bornoCount++
              break
            case STATES.CROSSRIVER:
              crossriverCount++
              break
            case STATES.DELTA:
              deltaCount++
              break
            case STATES.EBONYI:
              ebonyiCount++
              break
            case STATES.EDO:
              edoCount++
              break
            case STATES.EKITI:
              ekitiCount++
              break
            case STATES.ENUGU:
              enuguCount++
              break
            case STATES.GOMBE:
              gombeCount++
              break
            case STATES.IMO:
              imoCount++
              break
            case STATES.JIGAWA:
              jigawaCount++
              break
            case STATES.KADUNA:
              kadunaCount++
              break
            case STATES.KANO:
              kanoCount++
              break
            case STATES.KATSINA:
              katsinaCount++
              break
            case STATES.KEBBI:
              kebbiCount++
              break
            case STATES.KOGI:
              kogiCount++
              break
            case STATES.KWARA:
              kwaraCount++
              break
            case STATES.LAGOS:
              lagosCount++
              break
            case STATES.NASARAWA:
              nasarawaCount++
              break
            case STATES.NIGER:
              nigerCount++
              break
            case STATES.OGUN:
              ogunCount++
              break
            case STATES.ONDO:
              ondoCount++
              break
            case STATES.OSUN:
              osunCount++
              break
            case STATES.OYO:
              oyoCount++
              break
            case STATES.PLATEAU:
              plateauCount++
              break
            case STATES.RIVERS:
              riversCount++
              break
            case STATES.SOKOTO:
              sokotoCount++
              break
            case STATES.TARABA:
              tarabaCount++
              break
            case STATES.YOBE:
              yobeCount++
              break
            case STATES.ZAMFARA:
              zamfaraCount++
              break
            default:
              // Handle cases where the chapter does not match any state
              console.warn(`Unknown state: ${stateChapter}`)
          }
        })
    }


    const columns = [
        { field: 'fullName', headerName: 'FULLNAME', width: 250 },
        { field: 'email', headerName: 'EMAIL', width: 250 },
        { field: 'token', headerName: 'TOKEN', width: 100 },
        { field: 'chapter', headerName: 'STATE CHAPTER', width: 150 },
        { field: 'membershipType', headerName: 'GRADE', width: 150 },
        { field: 'paid', headerName: 'PAID', width: 100, renderCell: (params) => {
          return (
            params.row.paid === true ? <ThumbUpIcon size="small" color="success" /> : <ThumbDownIcon size="small" color="error" />
          )
        }},
        { field: 'collected', headerName: 'COLLECTED', width: 100, renderCell: (params) => {
          return (
            params.row.collected === true ? <ThumbUpIcon size="small" color="success" /> : <ThumbDownIcon size="small" color="error" />
          )
        }},
        {
            field: 'id',
            headerName: 'ACTIONS',
            width: 200,
            renderCell: (params) => {
                  const handlePaid = async () =>  {
                    const currentRow = params.row

                    try {
                        const response = await axios({
                            url: `http://localhost:3501/registrants`,
                            //url: `https://niob-agm-api.onrender.com/registrants`, // Server endpoint
                            data: {'id':currentRow.id, 'paid':true},
                            method: 'PATCH',
                            responseType: 'json', // Important
                        })

                        if (response.data.status === 201) {
                            alert(`${currentRow.fullName}'s payment has been received!`)

                            // Assuming rows are stored in state
                            setRows((prevRows) =>
                                prevRows.map((row) =>
                                    row.id === currentRow.id ? { ...row, paid: true } : row
                                )
                            )
                        }
                    } catch (err) {
                        console.log('error on clearing Registrant ' + err)
                    }
                  }

                  /*const handleTagged = async () =>  {
                    const currentRow = params.row

                    try {
                        const response = await axios({
                            //url: `http://localhost:3501/registrants`,
                            url: `https://niob-agm-api.onrender.com/registrants`, // Server endpoint
                            data: {'id':currentRow.id, 'tagged':true},
                            method: 'PATCH',
                            responseType: 'json', // Important
                        })

                        if (response.data.status === 201) {
                            alert(`${currentRow.fullName} has received a Tag!`)

                            // Assuming rows are stored in state
                            setRows((prevRows) =>
                                prevRows.map((row) =>
                                    row.id === currentRow.id ? { ...row, tagged: true } : row
                                )
                            )
                        }
                    } catch (err) {
                        console.log('error on tagging Registrant ' + err)
                    }
                  }*/

                  const handleCollected = async () =>  {
                    const currentRow = params.row

                    try {
                        const response = await axios({
                            url: `http://localhost:3501/registrants`,
                            //url: `https://niob-agm-api.onrender.com/registrants`, // Server endpoint
                            data: {'id':currentRow.id, 'collected':true},
                            method: 'PATCH',
                            responseType: 'json', // Important
                        })

                        if (response.data.status === 201) {
                            alert(`${currentRow.fullName} has collected Conference Material!`)

                            // Assuming rows are stored in state
                            setRows((prevRows) =>
                                prevRows.map((row) =>
                                    row.id === currentRow.id ? { ...row, collected: true } : row
                                )
                            )
                        }
                    } catch (err) {
                        console.log(`error on confirming Registrant's collection ` + err)
                    }
                  }

                  return (
                    <Stack direction="row" sx={{display: "inline-flex", justifyContent: "center", alignItems: "center"}}>
                        {isAccountant ?
                          (params.row.paid ? "CLEARED" :
                            <Button onClick={(event) => handlePaid(event, params.row.id)} color="success" variant="outlined">
                                CONFIRM PAY
                            </Button>
                          ) : isSchwagGiver ?
                          (params.row.collected ? "COLLECTED" :
                            <Button onClick={(event) => handleCollected(event, params.row.id)} color="success" variant="outlined"
                                    disabled={(!params.row.paid || params.row.collected) ? true : false}>
                                SCHWAG GIVEN
                            </Button>
                          ) : isSuperAdmin ? (
                            <>
                              {params.row.paid ? (
                                null
                              ) : (
                                <Button
                                  onClick={(event) => handlePaid(event, params.row.id)}
                                  color="success"
                                  variant="outlined"
                                  disabled={params.row.paid}
                                >
                                  CONFIRM PAY
                                </Button>
                              )}
                              {params.row.collected ? (
                                "DONE"
                              ) : (
                                <Button
                                  onClick={(event) => handleCollected(event, params.row.id)}
                                  color="success"
                                  variant="outlined"
                                  disabled={!params.row.paid || params.row.collected}
                                >
                                  SCHWAG GIVEN
                                </Button>
                              )}
                            </>
                          ) : null
                        }
                    </Stack>
                  )
            }
        }
    ]

    let content

    if (isLoading) {
        content = <PulseLoader color={"#03a455"} className="center" />
    }

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess) {
        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short'}).format(date)

        content = (
            <>
                <DashHeader />
                <Box sx={{
                  width: '100%', mb: '150px', mt: isMobile ? "10%" : "",
                  "& .MuiPaper-root": { backgroundColor: '#fafafa' },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: '#03a455',
                    color: '#fff'
                  },
                  "& .MuiDataGrid-filterForm": {
                    backgroundColor: '#9CD08F'
                  },
                  "& .MuiButtonBase-root": {
                    backgroundColor: "transparent !important",
                  },
                  p: "7% 2%"
                }}>
                    <p>{today}</p>
                    <h4>Welcome {firstName.toUpperCase()+" "+lastName.toUpperCase()}!</h4>

                    {(isSuperAdmin || isManager) &&
                        <Card sx={{ p: 2, borderRadius: '12px',
                        boxShadow: 1, border: '1px solid #ddd',
                        backgroundColor: "#fafafa",
                        color: "#0b1c08",
                        mb: 5}}>
                            <CardContent>
                              <Typography variant="h6" component="div">
                                Admin Stats
                              </Typography>
                              <Typography variant="subtitle2" color="#0b1c0890" sx={{ mt: 1 }}>
                                Total {Math.round((onSiteCount/totalCount)*100)}% registration <span role="img" aria-label="sunglasses">😎</span> during Conference/AGM
                              </Typography>
                              <Grid container spacing={2} sx={{ mt: 2, mb: isMobile ? 5 : "" }}>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Paper sx={{ p: 1, backgroundColor: '#8E3B46 !important', display: 'flex', alignItems: 'center' }}>
                                      <PeopleIcon sx={{ fontSize: 36, color: '#EDE7F6' }} />
                                    </Paper>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography variant="h5">
                                        {totalCount}
                                      </Typography>
                                      <Typography variant="subtitle2" color="#8E3B46" sx={{fontWeight:"700px !important"}}>
                                        Total Registrants
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Paper sx={{ p: 1, backgroundColor: '#4CAF50 !important', display: 'flex', alignItems: 'center' }}>
                                      <AccountBalanceWalletIcon sx={{ fontSize: 36, color: '#E8F5E9' }} />
                                    </Paper>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography variant="h5">
                                        {paidCount}
                                      </Typography>
                                      <Typography variant="subtitle2" color="#4CAF50" sx={{fontWeight:"700px !important"}}>
                                        Confirmed Payments
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Paper sx={{ p: 1, backgroundColor: '#FF9800 !important', display: 'flex', alignItems: 'center' }}>
                                      <RedeemIcon sx={{ fontSize: 36, color: '#FFF3E0' }} />
                                    </Paper>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography variant="h5">
                                        {collectedCount}
                                      </Typography>
                                      <Typography variant="subtitle2" color="#FF9800">
                                        Cleared for Material Collection
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Paper sx={{ p: 1, backgroundColor: '#673AB7 !important', display: 'flex', alignItems: 'center' }}>
                                      <PeopleIcon sx={{ fontSize: 36, color: '#FFF3E0' }} />
                                    </Paper>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography variant="h5">
                                        {onSiteCount}
                                      </Typography>
                                      <Typography variant="subtitle2" color="#673AB7">
                                        Registered during AGM
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </CardContent>
                        </Card>
                    }

                    {isManager &&
                        <Card sx={{ p: 2, borderRadius: '12px',
                        boxShadow: 1, border: '1px solid #ddd',
                        backgroundColor: "#fafafa",
                        color: "#0b1c08",
                        mb: 5}}>
                            <CardContent>
                              <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h1" sx={{color:"#404E5C"}}>
                                      {corporates + "%"}
                                    </Typography>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography sx={{fontSize:"12px", color:"#0b1c0890"}}>
                                        of all Registrants are
                                      </Typography>
                                      <Typography variant="subtitle2" color="#404E5C" sx={{fontWeight:"700px !important"}}>
                                        Corporates
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h1" sx={{color:"#404E5C"}}>
                                      {students + "%"}
                                    </Typography>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography sx={{fontSize:"12px", color:"#0b1c0890"}}>
                                        of all Registrants are
                                      </Typography>
                                      <Typography variant="subtitle2" color="#250001" sx={{fontWeight:"700px !important"}}>
                                        Students / Artisans
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h1" sx={{color:"#404E5C"}}>
                                      {fellows + "%"}
                                    </Typography>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography sx={{fontSize:"12px", color:"#0b1c0890"}}>
                                        of all Registrants are
                                      </Typography>
                                      <Typography variant="subtitle2" color="#CF1259" sx={{fontWeight:"700px !important"}}>
                                        Fellows
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h1" sx={{color:"#404E5C"}}>
                                      {nonmembers + "%"}
                                    </Typography>
                                    <Box sx={{ ml: 2 }}>
                                      <Typography sx={{fontSize:"12px", color:"#0b1c0890"}}>
                                        of all Registrants are
                                      </Typography>
                                      <Typography variant="subtitle2" color="#60712F" sx={{fontWeight:"700px !important"}}>
                                        Non-Members
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </CardContent>
                        </Card>
                    }

                    {!isManager && <>
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <Link to="/dash">
                            <Button variant="outlined" sx={{color:"#0b1c08"}} size="small" startIcon={<HomeIcon />}>
                                Home
                            </Button>
                        </Link>
                    </Stack>
                    <div style={{ height: 550, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10, 25, 50]}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                            components={{
                                Toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarQuickFilter />
                                        {isSuperAdmin && <GridToolbarExport />}
                                    </GridToolbarContainer>
                                )
                            }}
                            sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: '#d7d9d7', color: '#0b1c08' }, "& .MuiDataGrid-cell": { color: '#0b1c08' } }}
                        />
                    </div>
                    </>}

                    {isManager &&
                      <Stack direction="column" gap={5}>
                        <Stack direction={isMobile ? "column" : "row"} gap={5}>
                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ABIA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {abiaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((abiaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ABIA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {abiaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((abiaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ABUJA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {abujaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((abujaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ABUJA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {abujaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((abujaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ADAMAWA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {adamawaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((adamawaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ADAMAWA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {adamawaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((adamawaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              AKWA IBOM:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {akwaibomCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((akwaibomCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            AKWA IBOM:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {akwaibomCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((akwaibomCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ANAMBRA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {anambraCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((anambraCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ANAMBRA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {anambraCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((anambraCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              BAUCHI:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {bauchiCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((bauchiCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            BAUCHI:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {bauchiCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((bauchiCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              BAYELSA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {bayelsaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((bayelsaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            BAYELSA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {bayelsaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((bayelsaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              BENUE:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {benueCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((benueCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            BENUE:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {benueCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((benueCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              BORNO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {bornoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((bornoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            BORNO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {bornoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((bornoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              CROSS RIVER:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {crossriverCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((crossriverCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            CROSS RIVER:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {crossriverCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((crossriverCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              DELTA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {deltaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((deltaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            DELTA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {deltaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((deltaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              EBONYI:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {ebonyiCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((ebonyiCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            EBONYI:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {ebonyiCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((ebonyiCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>
                        </Stack>

                        <Stack direction={isMobile ? "column" : "row"} gap={5}>
                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              EDO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {edoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((edoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            EDO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {edoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((edoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              EKITI:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {ekitiCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((ekitiCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            EKITI:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {ekitiCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((ekitiCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ENUGU:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {enuguCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((enuguCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ENUGU:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {enuguCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((enuguCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              GOMBE:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {gombeCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((gombeCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            GOMBE:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {gombeCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((gombeCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              IMO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {imoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((imoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            IMO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {imoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((imoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              JIGAWA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {jigawaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((jigawaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            JIGAWA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {jigawaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((jigawaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KADUNA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {kadunaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((kadunaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KADUNA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {kadunaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((kadunaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KANO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {kanoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((kanoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KANO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {kanoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((kanoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KATSINA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {katsinaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((katsinaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KATSINA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {katsinaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((katsinaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KEBBI:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {kebbiCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((kebbiCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KEBBI:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {kebbiCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((kebbiCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KOGI:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {kogiCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((kogiCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KOGI:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {kogiCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((kogiCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              KWARA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {kwaraCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((kwaraCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            KWARA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {kwaraCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((kwaraCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>
                        </Stack>

                        <Stack direction={isMobile ? "column" : "row"} gap={5}>
                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              LAGOS:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {lagosCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((lagosCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            LAGOS:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {lagosCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((lagosCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              NASARAWA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {nasarawaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((nasarawaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            NASARAWA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {nasarawaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((nasarawaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              NIGER:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {nigerCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((nigerCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            NIGER:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {nigerCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((nigerCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              OGUN:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {ogunCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((ogunCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            OGUN:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {ogunCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((ogunCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ONDO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {ondoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((ondoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ONDO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {ondoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((ondoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              OSUN:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {osunCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((osunCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            OSUN:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {osunCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((osunCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              OYO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {oyoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((oyoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            OYO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {oyoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((oyoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              PLATEAU:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {plateauCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((plateauCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            PLATEAU:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {plateauCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((plateauCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>

                            <Grid sx={{ color: colors.primary[400], borderWidth: 1, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", width: isMobile ? "100%" : "33%" }} >
                                <List>
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              RIVERS:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {riversCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: '#000 !important' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((riversCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            RIVERS:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {riversCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: '#000 !important' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((riversCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              SOKOTO:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {sokotoCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((sokotoCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            SOKOTO:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {sokotoCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((sokotoCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              TARABA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {tarabaCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((tarabaCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            TARABA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {tarabaCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((tarabaCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              YOBE:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {yobeCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((yobeCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            YOBE:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {yobeCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((yobeCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>

                                  <Divider variant="middle" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                    { isMobile ?
                                    <ListItemText
                                        sx={{ maxWidth: "150px" }}
                                        primary={
                                          <React.Fragment>
                                            <Typography
                                              variant="h6"
                                              sx={{ fontSize: "10px" }}
                                              color={colors.greenAccent[400]}
                                            >
                                              ZAMFARA:
                                            </Typography>
                                            <Stack direction="row" gap={3}>
                                              {/* Display the count */}
                                              <Typography
                                                variant={isMobile ? "h5" : "h4"}
                                                align="left"
                                                sx={{ color: "#0b1c08" }}
                                              >
                                                {zamfaraCount}
                                              </Typography>

                                              <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              sx={{ height: '100%', borderColor: 'grey.400' }} />

                                              {/* Display the percentage */}
                                              <Typography
                                                variant="h4"
                                                sx={{ color: "#0b1c0890" }}
                                              >
                                                {totalCount > 0 ? ((zamfaraCount / totalCount) * 100).toFixed(1) : 0}%
                                              </Typography>
                                            </Stack>
                                          </React.Fragment>
                                        }
                                    />  :
                                    <>
                                    <ListItemText
                                      sx={{ maxWidth: "250px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant={ isMobile ? "h6" : "h4" }
                                            color={colors.greenAccent[400]}
                                          >
                                            ZAMFARA:
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        <React.Fragment>
                                          <Stack direction="row" gap={3}>
                                            {/* Display the count */}
                                            <Typography
                                              variant={isMobile ? "h5" : "h4"}
                                              align="left"
                                              sx={{ color: "#0b1c08" }}
                                            >
                                              {zamfaraCount}
                                            </Typography>

                                            <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ height: '100%', borderColor: 'grey.400' }} />

                                            {/* Display the percentage */}
                                            <Typography
                                              variant="h4"
                                              sx={{ color: "#0b1c0890" }}
                                            >
                                              {totalCount > 0 ? ((zamfaraCount / totalCount) * 100).toFixed(1) : 0}%
                                            </Typography>
                                          </Stack>
                                        </React.Fragment>
                                      }
                                    /></> }
                                  </ListItem>
                                </List>
                            </Grid>
                        </Stack>
                      </Stack>
                    }
                </Box>
            </>
        )
    }

    return content
}


/*const MyRegistrantsList = ({ roletype, center }) => {
    useTitle('NIOB Skill CaMP: All Registrants in your Center')
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isCenterManager, isCenter } = useAuth()
    const navigate = useNavigate()

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [pageSize, setPageSize] = useState(5)

    const handleEdit = (id) => navigate(`/dash/users/${id}`)
    const handleView = (id) => navigate(`/dash/users/user/${id}`)

    const columns = [
        { field: 'uln', headerName: 'ULN', flex: 1 },
        { field: 'firstName', headerName: 'FIRST NAME', flex: 1 },
        { field: 'lastName', headerName: 'LAST NAME', flex: 1 },
        { field: 'roles', headerName: 'ROLES', flex: 1, renderCell: (params) => params.row.roles.join(', ') },
        { field: 'centers', headerName: 'CENTRES', flex: 1, renderCell: (params) => params.row.centers.join(', ') },
        { field: 'qualifications', headerName: 'QUALIFICATIONS', flex: 1, renderCell: (params) => params.row.qualifications.join(', ') },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            sortable: false,
            renderCell: (params) => (
                <Button
                    color="success"
                    onClick={() => handleEdit(params.id)}
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
            ),
        },
    ]

    const visibleRows = useMemo(() => {
        if (!users) return []

        return users?.entities
            ? Object.values(users.entities).filter(user => (user.centers.includes(center) && user.roles.includes(roletype)))
            : []
    }, [users, center, roletype])

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />
    if (isError) content = <p className='errmsg'>{error?.data?.message}</p>

    if (isSuccess && (isCenterManager || isCenter)) {
        const userCount = visibleRows.length

        content = (
            <Box sx={{
              width: '100%', mb: '30px',
              "& .MuiPaper-root": { backgroundColor: '#030702' },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: '#0b1c08',
              },
              "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                backgroundColor: '#121212',
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: '#091506',
              },
              "& .MuiDataGrid-row:nth-of-type(even):hover": {
                backgroundColor: '#121212',
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: '#0b1c08'
              },
              "& .MuiDataGrid-filterForm": {
                backgroundColor: '#9CD08F'
              }
            }}>
                {userCount > 0 ? (
                    <DataGrid
                        rows={visibleRows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 25]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        autoHeight
                    />
                ) : (
                    <>
                        <CloudUploadIcon sx={{ fill: "0b1c08a9", fontSize: 120 }} />
                        <Typography variant="h3" sx={{ color: "efefef" }}>
                            No {roletype} Records.
                        </Typography>
                    </>
                )}
            </Box>
        )
    }

    return content
}*/

export default RegistrantsList
  //MyRegistrantsList
//}
