import {
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit'
import { apiSlice } from  '../../app/api/apiSlice'

const registrantsAdapter = createEntityAdapter({})

const initialState = registrantsAdapter.getInitialState()

export const registrantsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRegistrants: builder.query({
            query: () => ({
                url: '/registrants',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
              const loadedRegistrants = responseData.map(registrant => {
                  registrant.id = registrant._id
                  return registrant
              });
              return registrantsAdapter.setAll(initialState, loadedRegistrants)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Registrant', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Registrant', id }))
                    ]
                } else return [{ type: 'Registrant', id: 'LIST' }]
            }
        }),
        addNewRegistrant: builder.mutation({
            query: initialRegistrantData => ({
                url: '/registrants',
                method: 'POST',
                body: {
                    ...initialRegistrantData,
                }
            }),
            invalidatesTags: [
                { type: 'Registrant', id: "LIST" }
            ]
        }),
        updateRegistrant: builder.mutation({
            query: initialRegistrantData => ({
                url: '/registrants',
                method: 'PATCH',
                body: {
                    ...initialRegistrantData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Registrant', id: arg.id }
            ]
        }),
        deleteRegistrant: builder.mutation({
            query: ({ id }) => ({
                url: `/registrants`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Registrant', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetRegistrantsQuery,
    useAddNewRegistrantMutation,
    useUpdateRegistrantMutation,
    useDeleteRegistrantMutation,
} = registrantsApiSlice

// return the query result object
export const selectRegistrantsResult = registrantsApiSlice.endpoints.getRegistrants.select()

// creates memoized selector
const selectRegistrantsData = createSelector(
    selectRegistrantsResult,
    registrantsResult => registrantsResult.data // normalized state object with ids  & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllRegistrants,
    selectById: selectRegistrantById,
    selectIds: selectRegistrantIds
    // Pass in a selector that returns the registrants slice of state
} = registrantsAdapter.getSelectors(state => selectRegistrantsData(state) ?? initialState)
