import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, TextField, Typography, Avatar, Stack, FormGroup, FormControlLabel, Checkbox, Collapse, Alert } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import DashHeader from "../../components/DashHeader2"
import DashFooter from "../../components/DashFooter"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

const Login = () => {
  const isMobile = useMediaQuery("(max-width:600px)")

  const userRef = useRef()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [persist, setPersist] = usePersist()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()
  const [alert, setAlert] = useState(null)

  useEffect(() => {
      userRef.current.focus()
  }, [])

  useEffect(() => {
      setErrMsg('')
  }, [email, password])

  const handleSubmit = async (e) => {
      e.preventDefault()

      try {
          const { accessToken } = await login({ email, password }).unwrap()
          dispatch(setCredentials({ accessToken }))
          setEmail('')
          setPassword('')
          navigate('/dash', { replace: true })  // Use replace to avoid adding to history stack
      } catch (err) {
          if (!err.status || err.status === 500 || err.status === 501) {
              setAlert('No Server Response')
          } else if (err.status === 400) {
              setAlert('Missing Username or Password')
          } else if (err.status === 401) {
              setAlert('Unauthorized - Wrong Username or Password')
          } else {
              setAlert(err.data?.message)
          }
          //errRef.current.focus()
      }
  }

  const handleUserInput = (e) => setEmail(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)
  const handleToggle = () => setPersist(prev => !prev)

  return (
      <>
        <DashHeader />
        <Box sx={{
            width: "100%",
            p: isMobile ? "30% 5%" : "15% 35%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
            <Stack direction="row" gap={2} sx={{alignItems:"center"}}>
                <Avatar sx={{ color: "#fff", bgcolor: "#49a139" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h3" sx={{color:"#0b1c08"}}>
                    USER LOGIN
                </Typography>
            </Stack>

            <form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                    }}
                >
                    {/*Alert Messages to be displayed here*/}
                    <Collapse in={alert} sx={{ gridColumn: "span 4" }}>
                      <Alert
                        variant="outlined"
                        sx={{ color: "#ff0000" }}
                        severity="error"
                        aria-live="assertive"
                        onClose={() => {setAlert(null)}}
                      >
                          {alert}
                      </Alert>
                    </Collapse>

                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Username"
                        ref={userRef}
                        onChange={handleUserInput}
                        value={email}
                        name="email"
                        sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="password"
                        label="Password"
                        onChange={handlePwdInput}
                        value={password}
                        name="password"
                        sx={{ gridColumn: "span 4" }}
                    />
                </Box>
                <Box display="flex" mt="30px">
                    <FormGroup>
                        <FormControlLabel onChange={handleToggle} checked={persist} control={<Checkbox sx={{color:"#49a139"}} />} label="Trust This Device" />
                    </FormGroup>
                </Box>
                <Box display="flex" mt="10px">
                    <LoadingButton type="submit" loading={isLoading} sx={{color:"#fff"}} fullWidth variant="contained">
                        <span>Login</span>
                    </LoadingButton>
                </Box>
            </form>
        </Box>
      </>
  )
}

export default Login
